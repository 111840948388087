import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginAccData } from '../interfaces/login-acc-data';
import { LoginToken } from '../interfaces/login-token';
import { ResetPasswordRequest } from '../interfaces/reset-password-request.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements OnDestroy {
  private noUser = new BehaviorSubject<boolean>(true);
  private moderatorLoggedIn = new BehaviorSubject<boolean>(false);
  private companyLoggedIn = new BehaviorSubject<boolean>(false);
  private adminLoggedIn = new BehaviorSubject<boolean>(false);
  private registering = new BehaviorSubject<boolean>(false);

  noUser$ = this.noUser.asObservable();
  moderatorLoggedIn$ = this.moderatorLoggedIn.asObservable();
  companyLoggedIn$ = this.companyLoggedIn.asObservable();
  adminLoggedIn$ = this.adminLoggedIn.asObservable();
  registering$ = this.registering.asObservable();

  subscriptionRouter: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {}

  acitvateClient() {
    this.noUser.next(false);
    this.moderatorLoggedIn.next(true);
    this.companyLoggedIn.next(false);
    this.adminLoggedIn.next(false);
    this.registering.next(false);
  }

  activateCompany() {
    this.noUser.next(false);
    this.moderatorLoggedIn.next(false);
    this.companyLoggedIn.next(true);
    this.adminLoggedIn.next(false);
    this.registering.next(false);
  }

  activateAdmin() {
    this.noUser.next(false);
    this.moderatorLoggedIn.next(false);
    this.companyLoggedIn.next(false);
    this.adminLoggedIn.next(true);
    this.registering.next(false);
  }

  activateModerator() {
    this.noUser.next(false);
    this.moderatorLoggedIn.next(true);
    this.companyLoggedIn.next(false);
    this.adminLoggedIn.next(false);
    this.registering.next(false);
  }

  registeringUser() {
    this.noUser.next(false);
    this.moderatorLoggedIn.next(false);
    this.companyLoggedIn.next(false);
    this.adminLoggedIn.next(false);
    this.registering.next(true);
  }

  companyLogIn() {
    if (localStorage.getItem('candidateExists') === null) {
      return;
    } else {
      if (localStorage.getItem('candidateExists') === 'true') {
        this.activateCompany();
        this.router.navigate(['company/settings']);
        // this.router.navigate(['company/activities']);
        // this.router.navigate(['company/reg']);
        // this.router.navigate(['company/candidates']);
        // this.router.navigate([
        //   'company/candidates/40769627-5bf6-4640-8d6c-1ac131e72f5b',
        // ]);
        // this.router.navigate(['company/notifications']);
        // this.router.navigate(['/company/statistics']);
      } else if (localStorage.getItem('candidateExists') === 'false') {
        this.router.navigate(['/company/reg']);
        this.registeringUser();
      } else {
        this.router.navigate(['']);
        this.logOut();
      }
    }
  }

  adminLogIn() {
    this.activateAdmin();
    this.router.navigate(['admin/client/candidates']);
    // this.router.navigate(['admin/company/confirmation']);
    // this.router.navigate(['admin/company/companies']);
    // this.router.navigate(['admin/client/trafic']);
    // this.router.navigate(['admin/center/crm']);
    // this.router.navigate(['admin/client/payments-candidate']);
    // this.router.navigate(['/admin/client/settings']);
    // this.router.navigate(['/admin/company/settings']);
    // this.router.navigate(['/admin/blog/tag-list']);
    // this.router.navigate(['/admin/testimonials']);
    // this.router.navigate(['/admin/testimonials/edit-testimonial']);
    // this.router.navigate(['/admin/blogs']);
    // this.router.navigate(['/admin/blogs/blog-single-edit']);
    // this.router.navigate(['/admin/employers-list']);
    // this.router.navigate(['/admin/employers-list/employer']);
    // this.router.navigate([
    //   '/admin/admin-user-list/newUser/1fd05c85-8686-4b33-b81d-52f38a47092b',
    // ]);
  }

  moderatorLogin() {
    this.activateModerator();
    this.router.navigate(['admin/blogs']);
  }

  logOut() {
    this.noUser.next(true);
    this.moderatorLoggedIn.next(false);
    this.companyLoggedIn.next(false);
    this.adminLoggedIn.next(false);
    this.registering.next(false);
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userId');
    localStorage.removeItem('candidateId');
    localStorage.removeItem('regData');
    localStorage.removeItem('candidateExists');
    localStorage.removeItem('recruiterId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userLastName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('isRegruterConfirmed');
    this.router.navigate(['']);
  }

  onLogin(loginData: LoginAccData) {
    return this.http.post<LoginToken>(
      `${environment.API_JOOBZZ}${environment.API_POST_LOGIN}`,
      loginData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  getUserType() {
    let userRole = '';
    if (localStorage.getItem('userRole')) {
      userRole = localStorage.getItem('userRole') as string;
    }
    return userRole;
  }

  refreshToken() {
    return this.http.post<LoginToken>(
      `${environment.API_JOOBZZ}${environment.API_POST_REFRESH_TOKEN}`,
      '',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  resetPassword(data: ResetPasswordRequest) {
    return this.http.post(
      `${environment.API_JOOBZZ}${environment.API_POST_PASSWORD_RESET}`,
      data
    );
  }

  ngOnDestroy(): void {
    this.noUser.unsubscribe();
    this.moderatorLoggedIn.unsubscribe();
    this.companyLoggedIn.unsubscribe();
    this.adminLoggedIn.unsubscribe();
    this.subscriptionRouter.unsubscribe();
  }
}
