import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  noUser = this.logInService.noUser$;
  userModerator = this.logInService.moderatorLoggedIn$;
  userCompany = this.logInService.companyLoggedIn$;
  userAdmin = this.logInService.adminLoggedIn$;
  registeringUser = this.logInService.registering$;

  constructor(private logInService: LoginService) {}

  ngOnInit(): void {
    let userRole = '';

    if (localStorage.getItem('userRole')) {
      userRole = localStorage.getItem('userRole') as string;
      if (userRole === 'Moderator') {
        this.logInService.moderatorLogin();
      }
      if (userRole === 'Company') {
        this.logInService.companyLogIn();
      }
      if (userRole === 'Admin') {
        this.logInService.adminLogIn();
      }
    }

    // openLoginDialog(this.dialog);
    // singnUpDialog(this.dialog);
    // openResetPassDialog(this.dialog);
  }
}
