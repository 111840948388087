import { CanActivateFn, Router } from '@angular/router';
import { AdminInfoService } from '../services/admin-info.service';
import { LoginService } from '../services/login.service';
import { inject } from '@angular/core';

export const moderatorAndAdminGuard: CanActivateFn = (route, state) => {
  const adminInfoService = inject(AdminInfoService);
  const router = inject(Router);
  const loginService = inject(LoginService);
  if (adminInfoService.isAdmin() || adminInfoService.isModerator()) {
    console.log('true guard');
    return true;
  } else {
    console.log('false guard');
    loginService.logOut();
    return router.navigate(['']);
  }
};
