import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { adminGuard } from './guards/admin.guard';
import { moderatorAndAdminGuard } from './guards/moderator-and-admin.guard';

const routes: Routes = [
  {
    path: 'company/candidates/:userId',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/client/candidate-single/candidate-single.module'
      ).then((m) => m.CandidateSingleModule),
  },
  {
    path: 'admin/client/candidates',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/client/candidates/candidates.module').then(
        (m) => m.CandidatesModule
      ),
  },
  {
    path: 'admin/client/trafic',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/client/trafic/trafic.module').then(
        (m) => m.TraficModule
      ),
  },
  {
    path: 'admin/client/payments',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/client/payments/payments.module').then(
        (m) => m.PaymentsModule
      ),
  },
  {
    path: 'admin/client/payments-candidate',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/client/payments-candidate/payments-candidate.module'
      ).then((m) => m.PaymentsCandidateModule),
  },
  {
    path: 'admin/client/settings',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/client/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'admin/company/companies',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/company/companies/companies.module').then(
        (m) => m.CompaniesModule
      ),
  },

  {
    path: 'admin/company/trafic',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/company/trafic/trafic.module').then(
        (m) => m.TraficModule
      ),
  },
  {
    path: 'admin/company/status',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/company/status/status.module').then(
        (m) => m.StatusModule
      ),
  },
  {
    path: 'admin/company/settings',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/company/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'admin/company/confirmation',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/company/confirmation/confirmation.module').then(
        (m) => m.ConfirmationModule
      ),
  },
  {
    path: 'admin/center/settings',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/center/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'admin/center/crm',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/center/crm/crm.module').then((m) => m.CrmModule),
  },

  {
    path: 'admin/blogs',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/blog-list/blog-list.module').then(
        (m) => m.BlogListModule
      ),
  },
  {
    path: 'admin/blogs/blog-single-edit',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/blog-edit/blog-edit.module').then(
        (m) => m.BlogEditModule
      ),
  },
  {
    path: 'admin/blogs/blog-single-edit/:blogId',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/blog-edit/blog-edit.module').then(
        (m) => m.BlogEditModule
      ),
  },
  {
    path: 'admin/employers-list',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/employers/employers-list/employers-list.module'
      ).then((m) => m.EmployersListModule),
  },
  {
    path: 'admin/employers-list/employer',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/employers/employer-edit/employer-edit.module').then(
        (m) => m.EmployerEditModule
      ),
  },
  {
    path: 'admin/employers-list/employer/:employerId',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/employers/employer-edit/employer-edit.module').then(
        (m) => m.EmployerEditModule
      ),
  },
  {
    path: 'admin/blog/tag-list',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/tag-list/tag-list.module').then(
        (m) => m.TagListModule
      ),
  },
  {
    path: 'admin/blog/tag-list/tag',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/tag-edit/tag-edit.module').then(
        (m) => m.TagEditModule
      ),
  },
  {
    path: 'admin/blog/tag-list/tag/:tagID',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/tag-edit/tag-edit.module').then(
        (m) => m.TagEditModule
      ),
  },
  {
    path: 'admin/testimonials',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import('./pages/admin/testimonials/testimonials.module').then(
        (m) => m.TestimonialsModule
      ),
  },
  {
    path: 'admin/testimonials/edit-testimonial',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/testimonials/edit-tesitimonial/edit-tesitimonial.module'
      ).then((m) => m.EditTesitimonialModule),
  },
  {
    path: 'admin/testimonials/edit-testimonial/:testimonialId',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/testimonials/edit-tesitimonial/edit-tesitimonial.module'
      ).then((m) => m.EditTesitimonialModule),
  },
  {
    path: 'admin/center/unregistered-users',
    canActivate: [adminGuard],
    loadChildren: () =>
      import(
        './pages/admin/center/unregistered-users/unregistered-users.module'
      ).then((m) => m.UnregisteredUsersModule),
  },
  {
    path: 'admin/admin-user-list',
    canActivate: [adminGuard],
    loadChildren: () =>
      import(
        './pages/admin/adminUsers/admin-user-list/admin-user-list.module'
      ).then((m) => m.AdminUserListModule),
  },
  {
    path: 'admin/admin-user-list/newUser',
    canActivate: [adminGuard],
    loadChildren: () =>
      import(
        './pages/admin/adminUsers/admin-user-single/admin-user-single.module'
      ).then((m) => m.AdminUserSingleModule),
  },
  {
    path: 'admin/admin-user-list/newUser/:id',
    canActivate: [adminGuard],
    loadChildren: () =>
      import(
        './pages/admin/adminUsers/admin-user-single/admin-user-single.module'
      ).then((m) => m.AdminUserSingleModule),
  },
  {
    path: 'admin/list/job-list',
    loadChildren: () =>
      import(
        './pages/admin/lists/jobTitle/job-title-list/job-title-list.module'
      ).then((m) => m.JobTitleListModule),
  },
  {
    path: 'admin/list/job-list/jobTile',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/lists/jobTitle/job-title-add-edit/job-title-add-edit.module'
      ).then((m) => m.JobTitleAddEditModule),
  },
  {
    path: 'admin/list/job-list/jobTile/:id',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/lists/jobTitle/job-title-add-edit/job-title-add-edit.module'
      ).then((m) => m.JobTitleAddEditModule),
  },
  {
    path: 'admin/list/certificate-list',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/lists/certificate/certificate-list/certificate-list.module'
      ).then((m) => m.CertificateListModule),
  },
  {
    path: 'admin/list/certificate-list/certificate',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/lists/certificate/certificate-add-edit/certificate-add-edit.module'
      ).then((m) => m.CertificateAddEditModule),
  },
  {
    path: 'admin/list/certificate-list/certificate/:id',
    canActivate: [moderatorAndAdminGuard],
    loadChildren: () =>
      import(
        './pages/admin/lists/certificate/certificate-add-edit/certificate-add-edit.module'
      ).then((m) => m.CertificateAddEditModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/error404/error404.module').then((m) => m.Error404Module),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
